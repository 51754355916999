<template>
  <div class="box">
    <div class="profile-header">
      <div class="container">
        <div class="header-cards">
          <div class="row">
            <div class="col-md-6">
              <div class="cards-item">
                <div class="section-subtitle">
                  {{ showTitle("GENERAL INFORMATION") }}
                </div>
                <div class="content">
                  <div class="content-title">
                    {{ showTitle("Academic Calendar System") }}
                  </div>
                  <div class="content-title-value">
                    {{ showReport(getData.calendar_system) }}
                  </div>
                </div>
                <div class="content">
                  <div class="content-title">
                    {{ showTitle("Summer Session") }}
                  </div>
                  <div class="content-title-value">
                    <div v-if="getData.has_summer_session === 1">
                      {{ $t("schools.Offered") }}
                    </div>
                    <div v-if="getData.has_summer_session === 0">
                      {{ $t("schools.Not offered") }}
                    </div>
                  </div>
                </div>
                <div class="content">
                  <div class="content-title">
                    {{ showTitle("General Catalogue / Bulletin") }}
                  </div>
                  <div class="content-title-value">
                    {{ showReport(getData.catalog_type) }}
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="col-md-6">
              <div class="cards-item"></div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="sections section">
      <div class="section-title">
        {{ showTitle("UNDERGRADUATE EDUCATION") }}
      </div>
      <!-- <div class="content">
        <div class="content-title">Undergraduate Majors</div>
        <div class="content-title-value">
          View All Majors (64)
        </div>
      </div> -->
      <div class="content">
        <div class="content-title">
          {{ showTitle("Most Popular Disciplines") }}
        </div>
        <div class="content-title-value">
          {{ showReport(getData.popular_majors) }}
        </div>
      </div>
      <div class="content">
        <div class="content-title">
          {{ showTitle("Combined Liberal Arts/Professional Degree Programs") }}
        </div>
        <div class="content-title-value">
          {{ showReport(getData.la_pd_programs) }}
        </div>
      </div>
      <div class="content">
        <div class="content-title">{{ showTitle("Special Programs") }}</div>
        <div class="content-title-value">
          {{ showReport(getData.special_programs) }}
        </div>
      </div>
      <div class="content">
        <div class="content-title">{{ showTitle("Study Abroad") }}</div>
        <div class="content-title-value">
          <div v-if="getData.has_study_abroad === 1">
            {{ $t("schools.Offered") }}
          </div>
          <div v-if="getData.has_study_abroad === 0">
            {{ $t("schools.Not offered") }}
          </div>
        </div>
      </div>
      <div class="content">
        <div class="content-title">{{ showTitle("Online Degrees") }}</div>
        <div class="content-title-value">
          <div v-if="getData.has_online_degree === 1">
            {{ $t("schools.yesOrNo.Some Online Degree Programs") }}
          </div>
          <div v-if="getData.has_online_degree === 0">
            {{ $t("schools.yesOrNo.No Online Degree Programs") }}
          </div>
        </div>
      </div>
      <hr />
      <div class="section-title">
        {{ showTitle("CURRICULUM AND GRADUATION REQUIREMENTS") }}
      </div>
      <div class="content">
        <div class="content-title">
          {{ showTitle("General Education/Core Curriculum") }}
        </div>
        <div class="content-title-value">
          <div v-if="getData.has_gened === 1">
            {{ $t("schools.yesOrNo.Required for most or all students") }}
          </div>
          <div v-if="getData.has_gened === 0">
            {{ $t("schools.yesOrNo.Not required for most or all students") }}
          </div>
        </div>
      </div>
      <div class="content">
        <div class="content-title">{{ showTitle("Computer") }}</div>
        <div class="content-title-value">
          <div v-if="getData.has_comp_req === 1">
            {{
              $t("schools.yesOrNo.Students required to own/lease a computer")
            }}
          </div>
          <div v-if="getData.has_comp_req === 0">
            {{
              $t(
                "schools.yesOrNo.Students not required to own/lease a computer"
              )
            }}
          </div>
        </div>
      </div>
      <div class="content">
        <div class="content-title">{{ showTitle("Foreign Language") }}</div>
        <div class="content-title-value">
          <div v-if="getData.has_lang_req === 1">
            {{ $t("schools.Required") }}
          </div>
          <div v-if="getData.has_lang_req === 0">
            {{ $t("schools.Not required") }}
          </div>
        </div>
      </div>
      <div class="content">
        <div class="content-title">{{ showTitle("Math/Science") }}</div>
        <div class="content-title-value">
          <div v-if="getData.has_stem_req === 1">
            Required for all students
          </div>
          <div v-if="getData.has_stem_req === 0">
            Not required for all students
          </div>
        </div>
      </div>
      <hr />
      <div class="section-title">
        {{ showTitle("FACULTY AND INSTRUCTION") }}
      </div>
      <div class="content">
        <div class="content-title">{{ showTitle("Full-Time Faculty") }}</div>
        <div class="content-title-value">
          {{ showReport(getData.fulltime_faculty_count) }}
        </div>
      </div>
      <div class="content">
        <div class="content-title">{{ showTitle("Part-Time Faculty") }}</div>
        <div class="content-title-value">
          {{ showReport(getData.parttime_faculty_count) }}
        </div>
      </div>
      <div class="content">
        <div class="content-title">
          {{ showTitle("Full-Time Faculty with PhD/Terminal Degree") }}
        </div>
        <div class="content-title-value">
          {{ showReport(getData.phd_faculty_pct, "%") }}
        </div>
      </div>
      <div class="content">
        <div class="content-title">{{ showTitle("Regular Class Size") }}</div>
        <div class="content-title-value">
          <div class="chart-bar">
            <div class="row" v-if="getData.class_size_9_pct">
              <div class="col-md-3">
                2-9 students
              </div>
              <div class="col-md-9">
                <el-progress
                  :text-inside="true"
                  :stroke-width="32"
                  :color="theme"
                  :percentage="getData.class_size_9_pct"
                ></el-progress>
              </div>
            </div>
            <div class="row" v-if="getData.class_size_19_pct">
              <div class="col-md-3">
                10-19 students
              </div>
              <div class="col-md-9">
                <el-progress
                  :text-inside="true"
                  :stroke-width="32"
                  :color="theme"
                  :percentage="getData.class_size_19_pct"
                ></el-progress>
              </div>
            </div>
            <div class="row" v-if="getData.class_size_29_pct">
              <div class="col-md-3">
                20-29 students
              </div>
              <div class="col-md-9">
                <el-progress
                  :text-inside="true"
                  :stroke-width="32"
                  :color="theme"
                  :percentage="getData.class_size_29_pct"
                ></el-progress>
              </div>
            </div>
            <div class="row" v-if="getData.class_size_39_pct">
              <div class="col-md-3">
                30-39 students
              </div>
              <div class="col-md-9">
                <el-progress
                  :text-inside="true"
                  :stroke-width="32"
                  :color="theme"
                  :percentage="getData.class_size_39_pct"
                ></el-progress>
              </div>
            </div>
            <div class="row" v-if="getData.class_size_49_pct">
              <div class="col-md-3">
                40-49 students
              </div>
              <div class="col-md-9">
                <el-progress
                  :text-inside="true"
                  :stroke-width="32"
                  :color="theme"
                  :percentage="getData.class_size_49_pct"
                ></el-progress>
              </div>
            </div>
            <div class="row" v-if="getData.class_size_99_pct">
              <div class="col-md-3">
                50-99 students
              </div>
              <div class="col-md-9">
                <el-progress
                  :text-inside="true"
                  :stroke-width="32"
                  :color="theme"
                  :percentage="getData.class_size_99_pct"
                ></el-progress>
              </div>
            </div>
            <div class="row" v-if="getData.class_size_100_pct">
              <div class="col-md-3">
                Over 100 students
              </div>
              <div class="col-md-9">
                <el-progress
                  :text-inside="true"
                  :stroke-width="32"
                  :color="theme"
                  :percentage="getData.class_size_100_pct"
                ></el-progress>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="content-title">
          {{ showTitle("Discussion Section/Lab Class Size") }}
        </div>
        <div class="content-title-value">
          <div class="chart-bar">
            <div class="row" v-if="getData.section_size_9_pct">
              <div class="col-md-3">
                2-9 students
              </div>
              <div class="col-md-9">
                <el-progress
                  :text-inside="true"
                  :stroke-width="32"
                  :color="theme"
                  :percentage="getData.section_size_9_pct"
                ></el-progress>
              </div>
            </div>
            <div class="row" v-if="getData.section_size_19_pct">
              <div class="col-md-3">
                10-19 students
              </div>
              <div class="col-md-9">
                <el-progress
                  :text-inside="true"
                  :stroke-width="32"
                  :color="theme"
                  :percentage="getData.section_size_19_pct"
                ></el-progress>
              </div>
            </div>
            <div class="row" v-if="getData.section_size_29_pct">
              <div class="col-md-3">
                20-29 students
              </div>
              <div class="col-md-9">
                <el-progress
                  :text-inside="true"
                  :stroke-width="32"
                  :color="theme"
                  :percentage="getData.section_size_29_pct"
                ></el-progress>
              </div>
            </div>
            <div class="row" v-if="getData.section_size_39_pct">
              <div class="col-md-3">
                30-39 students
              </div>
              <div class="col-md-9">
                <el-progress
                  :text-inside="true"
                  :stroke-width="32"
                  :color="theme"
                  :percentage="getData.section_size_39_pct"
                ></el-progress>
              </div>
            </div>
            <div class="row" v-if="getData.section_size_49_pct">
              <div class="col-md-3">
                40-49 students
              </div>
              <div class="col-md-9">
                <el-progress
                  :text-inside="true"
                  :stroke-width="32"
                  :color="theme"
                  :percentage="getData.section_size_49_pct"
                ></el-progress>
              </div>
            </div>
            <div class="row" v-if="getData.section_size_99_pct">
              <div class="col-md-3">
                50-99 students
              </div>
              <div class="col-md-9">
                <el-progress
                  :text-inside="true"
                  :stroke-width="32"
                  :color="theme"
                  :percentage="getData.section_size_99_pct"
                ></el-progress>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="section-title">
        {{ showTitle("ADVANCED PLACEMENT") }}
      </div>
      <div class="content">
        <div class="content-title">
          {{ showTitle("International Baccalaureate") }}
        </div>
        <div class="content-title-value">
          <div v-if="getData.accepts_ib === 1">
            {{ $t("schools.Accepted") }}
          </div>
          <div v-if="getData.accepts_ib === 0">
            {{ $t("schools.Not accepted") }}
          </div>
        </div>
      </div>
      <div class="content">
        <div class="content-title">
          {{ showTitle("Advanced Placement (AP) Examinations") }}
        </div>
        <div class="content-title-value">
          <div v-if="getData.accepts_ap === 1">
            {{ $t("schools.yesOrNo.Accepted for placement and credit") }}
          </div>
          <div v-if="getData.accepts_ap === 0">
            {{ $t("schools.yesOrNo.Not accepted for placement and credit") }}
          </div>
        </div>
      </div>
      <div class="content">
        <div class="content-title">{{ showTitle("Sophomore Standing") }}</div>
        <div class="content-title-value">
          <div v-if="getData.has_sophomore_standing === 1">
            {{ $t("schools.yesOrNo.Available through AP examinations") }}
          </div>
          <div v-if="getData.has_sophomore_standing === 0">
            {{ $t("schools.yesOrNo.Not available through AP examinations") }}
          </div>
        </div>
      </div>
      <hr />
      <div class="section-title">
        {{ showTitle("ACADEMIC RESOURCES") }}
      </div>

      <div class="content">
        <div class="content-title">
          {{ showTitle("Library Available on Campus") }}
        </div>
        <div class="content-title-value">
          <div v-if="getData.has_campus_library === 1">
            {{ $t("schools.Yes") }}
          </div>
          <div v-if="getData.has_campus_library === 0">
            {{ $t("schools.No") }}
          </div>
        </div>
      </div>
      <div class="content">
        <div class="content-title">{{ showTitle("Holdings") }}</div>
        <div class="content-title-value">
          {{ showReport(toThousands(getData.library_holdings_count)) }}
        </div>
      </div>
      <!-- <div class="content">
        <div class="content-title">Computer Ownership</div>
        <div class="content-title-value">
          Students not required to own/lease a computer
        </div>
      </div> -->
      <div class="content">
        <div class="content-title">
          {{ showTitle("Computers Available on Campus") }}
        </div>
        <div class="content-title-value">
          {{ showReport(toThousands(getData.campus_computers_count)) }}
        </div>
      </div>
      <div class="content">
        <div class="content-title">
          {{ showTitle("Internet/E-mail Access") }}
        </div>
        <div class="content-title-value">
          <div v-if="getData.has_internet_access === 1">
            {{ $t("schools.Yes") }}
          </div>
          <div v-if="getData.has_internet_access === 0">
            {{ $t("schools.No") }}
          </div>
        </div>
      </div>
      <hr />
      <div class="section-title">
        {{ showTitle("ACADEMIC SUPPORT SERVICES") }}
      </div>
      <div class="content">
        <div class="content-title">{{ showTitle("Remedial Instruction") }}</div>
        <div class="content-title-value">
          <div v-if="getData.has_remedial_instruction === 1">
            {{ $t("schools.Offered") }}
          </div>
          <div v-if="getData.has_remedial_instruction === 0">
            {{ $t("schools.Not offered") }}
          </div>
        </div>
      </div>
      <div class="content">
        <div class="content-title">{{ showTitle("Tutoring") }}</div>
        <div class="content-title-value">
          <div v-if="getData.has_tutoring === 1">
            {{ $t("schools.Available") }}
          </div>
          <div v-if="getData.has_tutoring === 0">
            {{ $t("schools.Not Available") }}
          </div>
        </div>
      </div>
      <div class="content">
        <div class="content-title">
          {{ showTitle("Services for Learning Disabled Students") }}
        </div>
        <div class="content-title-value">
          <div v-if="getData.has_disability_service === 1">
            {{ $t("schools.Available") }}
          </div>
          <div v-if="getData.has_disability_service === 0">
            {{ $t("schools.Not Available") }}
          </div>
        </div>
      </div>
      <div class="content">
        <div class="content-title">
          {{ showTitle("Services for Physically Disabled Students") }}
        </div>
        <div class="content-title-value">
          {{ showReport(getData.disability_services) }}
        </div>
      </div>
      <!-- <hr />
      <div class="section-title">
        GRADUATE/PROFESSIONAL SCHOOL EDUCATION
      </div>
      <div class="content">
        <div class="content-title">Master's Degrees Offered</div>
        <div class="content-title-value">
          Master Of Education, Master Of Arts, Master Of Business
          Administration, Master Of Science, Master Of Liberal Arts, Master Of
          Accountancy, Master Of Arts In Christian Ministries
        </div>
      </div>
      <div class="content">
        <div class="content-title">Master's Programs of Study</div>
        <div class="content-title-value">
          View All Programs (17)
        </div>
      </div>
      <div class="content">
        <div class="content-title">Doctoral Degrees Offered</div>
        <div class="content-title-value">
          Doctor Of Ministry
        </div>
      </div>
      <div class="content">
        <div class="content-title">Doctoral Programs of Study</div>
        <div class="content-title-value">
          Doctoral Programs of Study
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import mixin from "../mixins/index.js";

export default {
  components: {},

  mixins: [mixin],

  props: ["getData", "lang", "theme"],
  data() {
    return {};
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {
    showTitle(val) {
      return this.$t("schools.Academic Section." + this.trimStr(val));
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../css/index.scss";
</style>
