<template>
  <div class="box">
    <div class="sections section">
      <el-tabs v-model="activeName" type="card" style="margin-top:20px">
        <el-tab-pane :label="showTitle('Undergraduate')" name="Undergraduate">
          <div class="section-title">
            {{ showTitle("ADMISSIONS OFFICE") }}
          </div>

          <div class="row mb-3">
            <div class="col-md-8">
              <div class="locationMap">
                <baidu-map
                  :center="center"
                  :zoom="zoom"
                  :scroll-wheel-zoom="true"
                  class="bmView"
                  ak="BnpYvwZd3AYaD5GGqSC7ywAXEZDkcDay"
                >
                  <bm-marker
                    :position="center"
                    :dragging="false"
                    :title="name"
                    animation="BMAP_ANIMATION_BOUNCE"
                  >
                  </bm-marker>
                </baidu-map>
                <div class="locationAddress">
                  <div class="section-title">
                    <div>{{ showTitle("School Location") }}</div>
                  </div>
                  <hr />
                  <div style="margin-top:20px">
                    <h6>
                      <small style="color:red;margin-right:5px;font-size:16px"
                        ><i class="fas fa-map-marker"></i></small
                      >{{ name }}
                    </h6>
                    <div>
                      <div>{{ showReport(getData.ao_address) }}</div>
                      <div>
                        {{ showReport(getData.ao_city) }},
                        {{ showReport(getData.ao_state) }},
                        {{ getData.ao_zipcode1 }}-{{ getData.ao_zipcode2 }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content">
            <div class="content-title">
              {{ showTitle("Address") }}
            </div>
            <div class="content-title-value">
              <div>{{ showReport(getData.ao_address) }}</div>
              <div>
                {{ showReport(getData.ao_city) }},
                {{ showReport(getData.ao_state) }}, {{ getData.ao_zipcode1 }}-{{
                  getData.ao_zipcode2
                }}
              </div>
            </div>
          </div>
          <div class="content">
            <div class="content-title">{{ showTitle("Phone") }}</div>
            <div class="content-title-value">
              {{ showReport(getData.ao_phone) }}
            </div>
          </div>
          <div class="content">
            <div class="content-title">{{ showTitle("Email") }}</div>
            <div class="content-title-value">
              {{ showReport(getData.ao_email) }}
            </div>
          </div>
          <div class="content">
            <div class="content-title">
              {{ showTitle("Undergraduate Admissions website") }}
            </div>
            <div class="content-title-value">
              <a
                :href="getData.admissions_info_url"
                v-if="getData.admissions_info_url"
                target="_blank"
                >{{ showTitle("See official website") }}</a
              >
              <div v-else>-</div>
            </div>
          </div>
          <div class="content">
            <div class="content-title">
              {{ showTitle("Undergraduate Admissions portal") }}
            </div>
            <div class="content-title-value">
              <a
                :href="getData.admissions_portal_url"
                v-if="getData.admissions_portal_url"
                target="_blank"
                >{{ showTitle("Go to your portal") }}</a
              >
              <div v-else>-</div>
            </div>
          </div>
          <div class="content">
            <div class="content-title">
              {{ showTitle("Undergraduate Transfer requirement") }}
            </div>
            <div class="content-title-value">
              <a
                :href="getData.admissions_transfer_url"
                v-if="getData.admissions_transfer_url"
                target="_blank"
                >{{ showTitle("See official website") }}</a
              >
              <div v-else>-</div>
            </div>
          </div>
          <div class="content">
            <div class="content-title">
              {{ showTitle("Undergraduate First year requirement") }}
            </div>
            <div class="content-title-value">
              <a
                :href="getData.undergrad_1st_yr_admissions_req_url"
                v-if="getData.undergrad_1st_yr_admissions_req_url"
                target="_blank"
                >{{ showTitle("See official website") }}</a
              >
              <div v-else>-</div>
            </div>
          </div>
          <div class="content">
            <div class="content-title">
              {{ showTitle("Undergraduate International Student Requirement") }}
            </div>
            <div class="content-title-value">
              <a
                :href="getData.undergrad_1st_yr_int_req_url"
                v-if="getData.undergrad_1st_yr_int_req_url"
                target="_blank"
                >{{ showTitle("See official website") }}</a
              >
              <div v-else>-</div>
            </div>
          </div>

          <hr class="solid" />
          <div class="section-title">
            {{ showTitle("APPLICATION DATES & FEES") }}
          </div>

          <!-- <div class="content">
            <div class="content-title">
              {{ showTitle("Regular Admission Deadline") }}
            </div>
            <div class="content-title-value">
              <div v-if="getData.rd_deadline_is_rolling === 0">
                {{ showReport(getData.rd_deadline) }}
              </div>
              <div v-if="getData.rd_deadline_is_rolling === 1">
                {{ $t("schools.yesOrNo.Rolling")}}
              </div>
            </div>
          </div> -->
          <div class="content">
            <div class="content-title">{{ showTitle("Application Fee") }}</div>
            <div class="content-title-value">
              {{ showReport(getData.domestic_application_fee, "", "$") }}
            </div>
          </div>
          <div class="content">
            <div class="content-title">
              {{ showTitle("Application Fee Waiver") }}
            </div>
            <div class="content-title-value">
              <div v-if="getData.has_application_fee_waiver === 0">
                {{ $t("schools.Not Available") }}
              </div>
              <div v-if="getData.has_application_fee_waiver === 1">
                {{ $t("schools.Available") }}
              </div>
            </div>
          </div>
          <!-- <div class="content">
            <div class="content-title">
              {{ showTitle("Regular Admission Notification") }}
            </div>
            <div class="content-title-value">
              <div v-if="getData.rd_notification_date_is_rolling === 0">
                {{ showReport(getData.rd_notification_date) }}
              </div>
              <div v-if="getData.rd_notification_date_is_rolling === 1">
                {{ $t("schools.yesOrNo.Rolling")}}
              </div>
            </div>
          </div> -->
          <!-- <div class="content">
            <div class="content-title">
              {{ showTitle("Accept Offer of Admission") }}
            </div>
            <div class="content-title-value">
              {{ showReport(getData.accept_offer_admission_date) }}
            </div>
          </div> -->
          <div class="content">
            <div class="content-title">
              {{ showTitle("Waiting List Used") }}
            </div>
            <div class="content-title-value">
              <div v-if="getData.has_waitlist === 0">
                {{ $t("schools.No") }}
              </div>
              <div v-if="getData.has_waitlist === 1">
                {{ $t("schools.Yes") }}
              </div>
            </div>
          </div>
          <div class="content">
            <div class="content-title">{{ showTitle("Defer Admission") }}</div>
            <div class="content-title-value">
              <div v-if="getData.can_defer === 0">
                {{ $t("schools.yesOrNo.Student cannot defer admission") }}
              </div>
              <div v-if="getData.can_defer === 1">
                {{ $t("schools.yesOrNo.Student can defer admission") }}
              </div>
            </div>
          </div>
          <div class="content">
            <div class="content-title">
              {{ showTitle("Transfer Admission") }}
            </div>
            <div class="content-title-value">
              <div v-if="getData.can_transfer === 0">
                {{ $t("schools.yesOrNo.Transfer applications not accepted") }}
              </div>
              <div v-if="getData.can_transfer === 1">
                {{ $t("schools.yesOrNo.Transfer applications accepted") }}
              </div>
            </div>
          </div>
          <div class="content">
            <div></div>
            <div class="content-title-value">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th></th>
                    <th>{{ showTitle("Admission Deadline") }}</th>
                    <th>{{ showTitle("Admission Notification") }}</th>
                    <th>{{ showTitle("Accept Offer of Admission") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>EA I</td>
                    <td>
                      <div v-if="getData.ea1_deadline_is_rolling === 0">
                        {{ showReport(getData.ea1_deadline) }}
                      </div>
                      <div v-else-if="getData.ea1_deadline_is_rolling === 1">
                        {{ $t("schools.yesOrNo.Rolling") }}
                      </div>
                      <div v-else>
                        -
                      </div>
                    </td>
                    <td>
                      <div
                        v-if="getData.ea1_notification_date_is_rolling === 0"
                      >
                        {{ showReport(getData.ea1_notification_date) }}
                      </div>
                      <div
                        v-else-if="
                          getData.ea1_notification_date_is_rolling === 1
                        "
                      >
                        {{ $t("schools.yesOrNo.Rolling") }}
                      </div>
                      <div v-else>
                        -
                      </div>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>ED I</td>
                    <td>
                      <div v-if="getData.ed1_deadline_is_rolling === 0">
                        {{ showReport(getData.ed1_deadline) }}
                      </div>
                      <div v-else-if="getData.ed1_deadline_is_rolling === 1">
                        {{ $t("schools.yesOrNo.Rolling") }}
                      </div>
                      <div v-else>
                        -
                      </div>
                    </td>
                    <td>
                      <div
                        v-if="getData.ed1_notification_date_is_rolling === 0"
                      >
                        {{ showReport(getData.rd_notification_date) }}
                      </div>
                      <div
                        v-else-if="
                          getData.ed1_notification_date_is_rolling === 1
                        "
                      >
                        {{ $t("schools.yesOrNo.Rolling") }}
                      </div>
                      <div v-else>
                        -
                      </div>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>EA II</td>
                    <td>
                      <div v-if="getData.ea2_deadline_is_rolling">
                        <div v-if="getData.ea2_deadline_is_rolling === 0">
                          {{ showReport(getData.ea2_deadline) }}
                        </div>
                        <div v-if="getData.ea2_deadline_is_rolling === 1">
                          {{ $t("schools.yesOrNo.Rolling") }}
                        </div>
                      </div>
                      <div v-else>
                        -
                      </div>
                    </td>
                    <td>
                      <div
                        v-if="getData.ea2_notification_date_is_rolling === 0"
                      >
                        {{ showReport(getData.rd_notification_date) }}
                      </div>
                      <div
                        v-else-if="
                          getData.ea2_notification_date_is_rolling === 1
                        "
                      >
                        {{ $t("schools.yesOrNo.Rolling") }}
                      </div>
                      <div v-else>
                        -
                      </div>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>ED II</td>
                    <td>
                      <div v-if="getData.ed2_deadline_is_rolling === 0">
                        {{ showReport(getData.ed2_deadline) }}
                      </div>
                      <div v-else-if="getData.ed2_deadline_is_rolling === 1">
                        {{ $t("schools.yesOrNo.Rolling") }}
                      </div>
                      <div v-else>
                        -
                      </div>
                    </td>
                    <td>
                      <div
                        v-if="getData.ed2_notification_date_is_rolling === 0"
                      >
                        {{ showReport(getData.ed2_notification_date) }}
                      </div>
                      <div
                        v-else-if="
                          getData.ed2_notification_date_is_rolling === 1
                        "
                      >
                        {{ $t("schools.yesOrNo.Rolling") }}
                      </div>
                      <div v-else>-</div>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Regular</td>
                    <td>
                      <div v-if="getData.rd_deadline_is_rolling === 0">
                        {{ showReport(getMD(getData.rd_deadline)) }}
                      </div>
                      <div v-else-if="getData.rd_deadline_is_rolling === 1">
                        {{ $t("schools.yesOrNo.Rolling") }}
                      </div>
                      <div v-else>-</div>
                    </td>
                    <td>
                      <div v-if="getData.rd_notification_date_is_rolling === 0">
                        {{ showReport(getMD(getData.rd_notification_date)) }}
                      </div>
                      <div
                        v-else-if="
                          getData.rd_notification_date_is_rolling === 1
                        "
                      >
                        {{ $t("schools.yesOrNo.Rolling") }}
                      </div>
                      <div v-else>-</div>
                    </td>
                    <td>
                      {{ showReport(getData.accept_offer_admission_date) }}
                    </td>
                  </tr>
                  <tr>
                    <td>Transfer</td>
                    <td>
                      <div v-if="getData.tr_deadline_is_rolling === 0">
                        {{ showReport(getData.tr_deadline) }}
                      </div>
                      <div v-else-if="getData.tr_deadline_is_rolling === 1">
                        {{ $t("schools.yesOrNo.Rolling") }}
                      </div>
                      <div v-else>-</div>
                    </td>
                    <td>
                      <div v-if="getData.tr_notification_date_is_rolling === 0">
                        {{ showReport(getData.tr_notification_date) }}
                      </div>
                      <div
                        v-else-if="
                          getData.tr_notification_date_is_rolling === 1
                        "
                      >
                        {{ $t("schools.yesOrNo.Rolling") }}
                      </div>
                      <div v-else>-</div>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- <hr class="solid" />
          <div class="section-subtitle">
            EARLY ADMISSION
          </div>
          <div class="content">
            <div class="content-title">Early Decision Offered</div>
            <div class="content-title-value">
              No
            </div>
          </div> -->
          <hr class="solid" />
          <div class="section-title">
            {{ showTitle("APPLICATION FORM") }}
          </div>
          <div class="content">
            <div class="content-title">
              {{ showTitle("Common Application") }}
            </div>
            <div class="content-title-value">
              <div v-if="getData.accepts_commonapp === 0">
                {{ $t("schools.Not accepted") }}
              </div>
              <div v-if="getData.accepts_commonapp === 1">
                {{ $t("schools.Accepted") }}
              </div>
            </div>
          </div>
          <div class="content">
            <div class="content-title">
              {{ showTitle("Universal College Application") }}
            </div>
            <div class="content-title-value">
              <div v-if="getData.accepts_universalapp === 0">
                {{ $t("schools.Not accepted") }}
              </div>
              <div v-if="getData.accepts_universalapp === 1">
                {{ $t("schools.Accepted") }}
              </div>
            </div>
          </div>
          <div class="content">
            <div class="content-title">
              {{ showTitle("Electronic Application") }}
            </div>
            <div class="content-title-value">
              <div v-if="getData.accepts_electronicapp === 0">
                {{ $t("schools.Not accepted") }}
              </div>
              <div v-if="getData.accepts_electronicapp === 1">
                {{ $t("schools.Accepted") }}
              </div>
            </div>
          </div>
          <hr class="solid" />
          <div class="section-title">
            {{ showTitle("FRESHMAN ADMISSION REQS") }}
          </div>
          <div class="section-subtitle">
            {{ showTitle("HIGH SCHOOL PREPARATION") }}
          </div>
          <div class="content">
            <div class="content-title">
              {{ showTitle("High School Graduation") }}
            </div>
            <div class="content-title-value">
              {{ showReport(getData.hs_diploma_policy) }}
            </div>
          </div>
          <div class="content">
            <div class="content-title">
              {{ showTitle("High School Program") }}
            </div>
            <div class="content-title-value">
              {{ showReport(getData.hs_program_policy) }}
            </div>
          </div>
          <hr class="solid" />
          <div class="section-subtitle">
            {{ showTitle("HIGH SCHOOL UNITS REQ OR RECOMMENDED") }}
          </div>
          <div class="content">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Subject</th>
                  <th>Required Units</th>
                  <th>Recommended Units</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>English</td>
                  <td>{{ showReport(getData.hs_english_required) }}</td>
                  <td>{{ showReport(getData.hs_english_recommended) }}</td>
                </tr>
                <tr>
                  <td>Mathematics</td>
                  <td>{{ showReport(getData.hs_math_required) }}</td>
                  <td>{{ showReport(getData.hs_math_recommended) }}</td>
                </tr>
                <tr>
                  <td>Science</td>
                  <td>{{ showReport(getData.hs_science_required) }}</td>
                  <td>{{ showReport(getData.hs_science_recommended) }}</td>
                </tr>
                <tr>
                  <td>Foreign Language</td>
                  <td>{{ showReport(getData.hs_language_required) }}</td>
                  <td>{{ showReport(getData.hs_language_recommended) }}</td>
                </tr>
                <tr>
                  <td>Social Studies</td>
                  <td>{{ showReport(getData.hs_social_required) }}</td>
                  <td>{{ showReport(getData.hs_social_recommended) }}</td>
                </tr>
                <tr>
                  <td>History</td>
                  <td>{{ showReport(getData.hs_history_required) }}</td>
                  <td>{{ showReport(getData.hs_history_recommended) }}</td>
                </tr>
                <tr>
                  <td>Academic Electives</td>
                  <td>{{ showReport(getData.hs_elective_required) }}</td>
                  <td>{{ showReport(getData.hs_elective_recommended) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="section-subtitle">
            {{ showTitle("EXAMINATIONS") }}
          </div>
          <div class="content">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Subjects</th>
                  <th>Required Units</th>
                  <th>Due in Admissions Office</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>SAT or ACT</td>
                  <td>{{ showReport(getData.satact_policy) }}</td>
                  <td>{{ showReport(getData.satact_scores_due) }}</td>
                </tr>
                <tr>
                  <td>SAT Only</td>
                  <td>{{ showReport(getData.sat_policy) }}</td>
                  <td>{{ showReport(getData.sat_scores_due) }}</td>
                </tr>
                <tr>
                  <td>ACT Only</td>
                  <td>{{ showReport(getData.act_policy) }}</td>
                  <td>{{ showReport(getData.act_scores_due) }}</td>
                </tr>
                <tr>
                  <td>SAT and ACT Subject Tests, or ACT</td>
                  <td>{{ showReport(getData.satsat2act_policy) }}</td>
                  <td></td>
                </tr>
                <tr>
                  <td>SAT Subject Tests Only</td>
                  <td>{{ showReport(getData.sat2_policy) }}</td>
                  <td></td>
                </tr>
                <tr>
                  <td>SAT Essay Component Policy</td>
                  <td>{{ showReport(getData.sat_essay_policy) }}</td>
                  <td></td>
                </tr>
                <tr>
                  <td>ACT Writing Test Policy</td>
                  <td>{{ showReport(getData.act_essay_policy) }}</td>
                  <td></td>
                </tr>
                <!-- <tr>
                  <td>ACT Writing Test Policy</td>
                  <td>{{ showReport(getData.essay_policy) }}</td>
                  <td></td>
                </tr> -->
              </tbody>
            </table>
          </div>
          <div class="section-subtitle">
            {{ showTitle("International Students") }}
          </div>
          <div class="content">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Subject</th>
                  <th>Required Units</th>
                  <th>Comments</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>TOEFL</td>
                  <td>{{ showReport(getData.toefl_requirement) }}</td>
                  <td>{{ showReport(getData.toefl_requirement_comments) }}</td>
                </tr>
                <tr>
                  <td>IELTS</td>
                  <td>{{ showReport(getData.ielts_requirement) }}</td>
                  <td>{{ showReport(getData.ielts_requirement_comments) }}</td>
                </tr>
                <tr>
                  <td>Duolingo</td>
                  <td>{{ showReport(getData.duolingo_requirement) }}</td>
                  <td>
                    {{ showReport(getData.duolingo_requirement_comments) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <hr />
          <div class="section-title">
            {{ showTitle("OTHER APPLICATION REQUIREMENTS") }}
          </div>
          <div class="content">
            <div class="content-title">{{ showTitle("Interview") }}</div>
            <div class="content-title-value">
              {{ showReport(getData.interview_policy) }}
            </div>
          </div>
          <div class="content">
            <div class="content-title">
              {{ showTitle("Essay or Personal Statement") }}
            </div>
            <div class="content-title-value">
              {{ showReport(getData.college_essay_policy) }}
            </div>
          </div>
          <div class="content">
            <div class="content-title">{{ showTitle("Financial Need") }}</div>
            <div class="content-title-value">
              <div v-if="getData.financial_need_considered === 0">
                {{
                  $t(
                    "schools.yesOrNo.Financial need is not a consideration in the admissions process"
                  )
                }}
              </div>
              <div v-if="getData.financial_need_considered === 1">
                {{
                  $t(
                    "schools.yesOrNo.Financial need is a consideration in the admissions process"
                  )
                }}
              </div>
            </div>
          </div>
          <hr />
          <div class="section-title">
            {{ showTitle("SELECTION OF STUDENTS") }}
          </div>
          <div>
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>{{ showTitle("Factor") }}</th>
                  <th>{{ showTitle("Very Important") }}</th>
                  <th>{{ showTitle("Important") }}</th>
                  <th>{{ showTitle("Considered") }}</th>
                  <th>{{ showTitle("Not Considered") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="getData.impt_has_record">
                  <td>Rigor of Secondary School Record</td>
                  <td v-for="index in 4" :key="index">
                    <span v-if="getData.impt_has_record === index">
                      <i class="fas fa-check-square"></i>
                    </span>
                  </td>
                </tr>
                <tr v-if="getData.impt_gpa">
                  <td>{{ showTitle("Academic GPA") }}</td>
                  <td v-for="index in 4" :key="index">
                    <span v-if="getData.impt_gpa === index">
                      <i class="fas fa-check-square"></i>
                    </span>
                  </td>
                </tr>
                <tr v-if="getData.impt_tests">
                  <td>Standardized Tests</td>
                  <td v-for="index in 4" :key="index">
                    <span v-if="getData.impt_tests === index">
                      <i class="fas fa-check-square"></i>
                    </span>
                  </td>
                </tr>
                <tr v-if="getData.impt_rank">
                  <td>{{ showTitle("Class Rank") }}</td>
                  <td v-for="index in 4" :key="index">
                    <span v-if="getData.impt_rank === index">
                      <i class="fas fa-check-square"></i>
                    </span>
                  </td>
                </tr>
                <tr v-if="getData.impt_recommendations">
                  <td>{{ showTitle("Recommendations") }}</td>
                  <td v-for="index in 4" :key="index">
                    <span v-if="getData.impt_recommendations === index">
                      <i class="fas fa-check-square"></i>
                    </span>
                  </td>
                </tr>
                <tr v-if="getData.impt_essay">
                  <td>{{ showTitle("Essay") }}</td>
                  <td v-for="index in 4" :key="index">
                    <span v-if="getData.impt_essay === index">
                      <i class="fas fa-check-square"></i>
                    </span>
                  </td>
                </tr>
                <tr v-if="getData.impt_interview">
                  <td>{{ showTitle("Interview") }}</td>
                  <td v-for="index in 4" :key="index">
                    <span v-if="getData.impt_interview === index">
                      <i class="fas fa-check-square"></i>
                    </span>
                  </td>
                </tr>
                <tr v-if="getData.impt_interest">
                  <td>Level of Applicant's Interest</td>
                  <td v-for="index in 4" :key="index">
                    <span v-if="getData.impt_interest === index">
                      <i class="fas fa-check-square"></i>
                    </span>
                  </td>
                </tr>
                <tr v-if="getData.activitie">
                  <td>Extracurricular Activities</td>
                  <td v-for="index in 4" :key="index">
                    <span v-if="getData.activitie === index">
                      <i class="fas fa-check-square"></i>
                    </span>
                  </td>
                </tr>
                <tr v-if="getData.volunteering">
                  <td>Volunteer Work</td>
                  <td v-for="index in 4" :key="index">
                    <span v-if="getData.volunteering === index">
                      <i class="fas fa-check-square"></i>
                    </span>
                  </td>
                </tr>
                <tr v-if="getData.talent">
                  <td>Particular Talent/Ability</td>
                  <td v-for="index in 4" :key="index">
                    <span v-if="getData.talent === index">
                      <i class="fas fa-check-square"></i>
                    </span>
                  </td>
                </tr>
                <tr v-if="getData.personality">
                  <td>Character/Personal Qualities</td>
                  <td v-for="index in 4" :key="index">
                    <span v-if="getData.personality === index">
                      <i class="fas fa-check-square"></i>
                    </span>
                  </td>
                </tr>
                <tr v-if="getData.first_gen">
                  <td>First Generation to Attend College</td>
                  <td v-for="index in 4" :key="index">
                    <span v-if="getData.first_gen === index">
                      <i class="fas fa-check-square"></i>
                    </span>
                  </td>
                </tr>
                <tr v-if="getData.state_residency">
                  <td>State Residency</td>
                  <td v-for="index in 4" :key="index">
                    <span v-if="getData.state_residency === index">
                      <i class="fas fa-check-square"></i>
                    </span>
                  </td>
                </tr>
                <tr v-if="getData.geography">
                  <td>Geographic Residence</td>
                  <td v-for="index in 4" :key="index">
                    <span v-if="getData.geography === index">
                      <i class="fas fa-check-square"></i>
                    </span>
                  </td>
                </tr>
                <tr v-if="getData.alumnus_relationship">
                  <td>Relation with Alumnus</td>
                  <td v-for="index in 4" :key="index">
                    <span v-if="getData.alumnus_relationship === index">
                      <i class="fas fa-check-square"></i>
                    </span>
                  </td>
                </tr>
                <tr v-if="getData.religion">
                  <td>Religious Affiliation/Commitment</td>
                  <td v-for="index in 4" :key="index">
                    <span v-if="getData.religion === index">
                      <i class="fas fa-check-square"></i>
                    </span>
                  </td>
                </tr>
                <tr v-if="getData.ethnicity">
                  <td>Ethnicity</td>
                  <td v-for="index in 4" :key="index">
                    <span v-if="getData.ethnicity === index">
                      <i class="fas fa-check-square"></i>
                    </span>
                  </td>
                </tr>
                <tr v-if="getData.work_experience">
                  <td>Work Experience</td>
                  <td v-for="index in 4" :key="index">
                    <span v-if="getData.work_experience === index">
                      <i class="fas fa-check-square"></i>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- <div class="content">
            <div class="content-title">Very Important</div>
            <div class="content-title-value">
              See All (2)
            </div>
          </div>
          <div class="content">
            <div class="content-title">Considered</div>
            <div class="content-title-value">
              See All (4)
            </div>
          </div>
          <div class="content">
            <div class="content-title">Not Considered</div>
            <div class="content-title-value">
              See All (5)
            </div>
          </div> -->
          <hr />
          <div class="section-title">
            {{ showTitle("PROFILE OF FALL ADMISSION") }}
          </div>
          <div class="content">
            <div class="content-title">
              {{ showTitle("Overall Admission Rate") }}
            </div>
            <div class="content-title-value">
              {{ getData.acceptance_pct_overall }}% of
              {{ toThousands(getData.applicant_overall_count) }} applicants were
              admitted
              <div class="content">
                <div class="content-title">Women</div>
                <div class="content-title-value">
                  {{ getData.acceptance_pct_w }}% of
                  {{ toThousands(getData.applicant_w_count) }} applicants were
                  admitted
                </div>
              </div>
              <div class="content">
                <div class="content-title">Men</div>
                <div class="content-title-value">
                  {{ getData.acceptance_pct_m }}% of
                  {{ toThousands(getData.applicant_m_count) }} applicants were
                  admitted
                </div>
              </div>
            </div>
          </div>
          <div class="content">
            <div class="content-title">
              {{ showTitle("Students Enrolled") }}
            </div>
            <div class="content-title-value">
              {{ toThousands(getData.enrolled_overall_count) }} ({{
                getData.yield_pct_overall
              }}%) of {{ toThousands(getData.admitted_overall_count) }} admitted
              students enrolled
              <div class="content">
                <div class="content-title">Women</div>
                <div class="content-title-value">
                  {{ toThousands(getData.enrolled_w_count) }} ({{
                    getData.yield_pct_w
                  }}%) of {{ toThousands(getData.admitted_w_count) }} admitted
                  students enrolled
                </div>
              </div>
              <div class="content">
                <div class="content-title">Men</div>
                <div class="content-title-value">
                  {{ toThousands(getData.enrolled_m_count) }} ({{
                    getData.yield_pct_m
                  }}%) of {{ toThousands(getData.admitted_m_count) }} admitted
                  students enrolled
                </div>
              </div>
            </div>
          </div>
          <hr class="solid" />
          <div class="section-subtitle">
            {{
              showTitle("GRADE POINT AVERAGE OF ENROLLED FRESHMEN (4 SCALE)")
            }}
          </div>
          <div class="content-title-value">
            <div class="chart-bar">
              <div class="row" v-if="getData.gpa375_pct >= 0">
                <div class="col-md-3">
                  3.75 and Above
                </div>
                <div class="col-md-9">
                  <el-progress
                    :text-inside="true"
                    :stroke-width="32"
                    :color="theme"
                    :percentage="getData.gpa375_pct"
                  ></el-progress>
                </div>
              </div>
              <div class="row" v-if="getData.gpa350_pct >= 0">
                <div class="col-md-3">
                  3.50 - 3.74
                </div>
                <div class="col-md-9">
                  <el-progress
                    :text-inside="true"
                    :stroke-width="32"
                    :color="theme"
                    :percentage="getData.gpa350_pct"
                  ></el-progress>
                </div>
              </div>
              <div class="row" v-if="getData.gpa325_pct >= 0">
                <div class="col-md-3">
                  3.25 - 3.49
                </div>
                <div class="col-md-9">
                  <el-progress
                    :text-inside="true"
                    :stroke-width="32"
                    :color="theme"
                    :percentage="getData.gpa325_pct"
                  ></el-progress>
                </div>
              </div>
              <div class="row" v-if="getData.gpa300_pct >= 0">
                <div class="col-md-3">
                  3.00 - 3.24
                </div>
                <div class="col-md-9">
                  <el-progress
                    :text-inside="true"
                    :stroke-width="32"
                    :color="theme"
                    :percentage="getData.gpa300_pct"
                  ></el-progress>
                </div>
              </div>
              <div class="row" v-if="getData.gpa250_pct >= 0">
                <div class="col-md-3">
                  2.50 - 2.99
                </div>
                <div class="col-md-9">
                  <el-progress
                    :text-inside="true"
                    :stroke-width="32"
                    :color="theme"
                    :percentage="getData.gpa250_pct"
                  ></el-progress>
                </div>
              </div>
              <div class="row" v-if="getData.gpa200_pct >= 0">
                <div class="col-md-3">
                  2.00 - 2.49
                </div>
                <div class="col-md-9">
                  <el-progress
                    :text-inside="true"
                    :stroke-width="32"
                    :color="theme"
                    :percentage="getData.gpa200_pct"
                  ></el-progress>
                </div>
              </div>
            </div>
          </div>
          <hr class="solid" />
          <div class="section-subtitle">
            {{ showTitle("SAT SCORES OF ENROLLED FRESHMEN") }}
          </div>
          <div class="content">
            <div class="content-title">
              SAT Math:
              <span v-if="getData.avg_sat_math"
                >{{ getData.avg_sat_math }} average,</span
              >
              <span v-if="getData.avg_sat_math_min">
                {{ getData.avg_sat_math_min }} -
                {{ getData.avg_sat_math_max }} range of middle 50%
              </span>
            </div>
            <div class="content-title-value">
              <div class="chart-bar">
                <div class="row" v-if="getData.sat_math700_pct">
                  <div class="col-md-3">
                    Score of 700 - 800
                  </div>
                  <div class="col-md-9">
                    <el-progress
                      :text-inside="true"
                      :stroke-width="32"
                      :color="theme"
                      :percentage="getData.sat_math700_pct"
                    ></el-progress>
                  </div>
                </div>
                <div class="row" v-if="getData.sat_math600_pct">
                  <div class="col-md-3">
                    Score of 600 - 700
                  </div>
                  <div class="col-md-9">
                    <el-progress
                      :text-inside="true"
                      :stroke-width="32"
                      :color="theme"
                      :percentage="getData.sat_math600_pct"
                    ></el-progress>
                  </div>
                </div>
                <div class="row" v-if="getData.sat_math500_pct">
                  <div class="col-md-3">
                    Score of 500 - 600
                  </div>
                  <div class="col-md-9">
                    <el-progress
                      :text-inside="true"
                      :stroke-width="32"
                      :color="theme"
                      :percentage="getData.sat_math500_pct"
                    ></el-progress>
                  </div>
                </div>
                <div class="row" v-if="getData.sat_math400_pct">
                  <div class="col-md-3">
                    Score of 400 - 500
                  </div>
                  <div class="col-md-9">
                    <el-progress
                      :text-inside="true"
                      :stroke-width="32"
                      :color="theme"
                      :percentage="getData.sat_math400_pct"
                    ></el-progress>
                  </div>
                </div>
                <div class="row" v-if="getData.sat_math300_pct">
                  <div class="col-md-3">
                    Score of 300 - 400
                  </div>
                  <div class="col-md-9">
                    <el-progress
                      :text-inside="true"
                      :stroke-width="32"
                      :color="theme"
                      :percentage="getData.sat_math300_pct"
                    ></el-progress>
                  </div>
                </div>
                <div class="row" v-if="getData.sat_math200_pct">
                  <div class="col-md-3">
                    Score of 200 - 300
                  </div>
                  <div class="col-md-9">
                    <el-progress
                      :text-inside="true"
                      :stroke-width="32"
                      :color="theme"
                      :percentage="getData.sat_math200_pct"
                    ></el-progress>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content">
            <div class="content-title">
              SAT EBRW:
              <span v-if="getData.avg_sat_e"
                >{{ getData.avg_sat_e }} average,</span
              >
              <span v-if="getData.avg_sat_english_min">
                {{ getData.avg_sat_english_min }} -
                {{ getData.avg_sat_english_max }} range of middle 50%
              </span>
            </div>
            <div class="content-title-value">
              <div class="chart-bar">
                <div class="row" v-if="getData.sat_english700_pct">
                  <div class="col-md-3">
                    Score of 700 - 800
                  </div>
                  <div class="col-md-9">
                    <el-progress
                      :text-inside="true"
                      :stroke-width="32"
                      :color="theme"
                      :percentage="getData.sat_english700_pct"
                    ></el-progress>
                  </div>
                </div>
                <div class="row" v-if="getData.sat_english600_pct">
                  <div class="col-md-3">
                    Score of 600 - 700
                  </div>
                  <div class="col-md-9">
                    <el-progress
                      :text-inside="true"
                      :stroke-width="32"
                      :color="theme"
                      :percentage="getData.sat_english600_pct"
                    ></el-progress>
                  </div>
                </div>
                <div class="row" v-if="getData.sat_english500_pct">
                  <div class="col-md-3">
                    Score of 500 - 600
                  </div>
                  <div class="col-md-9">
                    <el-progress
                      :text-inside="true"
                      :stroke-width="32"
                      :color="theme"
                      :percentage="getData.sat_english500_pct"
                    ></el-progress>
                  </div>
                </div>
                <div class="row" v-if="getData.sat_english400_pct">
                  <div class="col-md-3">
                    Score of 400 - 500
                  </div>
                  <div class="col-md-9">
                    <el-progress
                      :text-inside="true"
                      :stroke-width="32"
                      :color="theme"
                      :percentage="getData.sat_english400_pct"
                    ></el-progress>
                  </div>
                </div>
                <div class="row" v-if="getData.sat_english300_pct">
                  <div class="col-md-3">
                    Score of 300 - 400
                  </div>
                  <div class="col-md-9">
                    <el-progress
                      :text-inside="true"
                      :stroke-width="32"
                      :color="theme"
                      :percentage="getData.sat_english300_pct"
                    ></el-progress>
                  </div>
                </div>
                <div class="row" v-if="getData.sat_english200_pct">
                  <div class="col-md-3">
                    Score of 200 - 300
                  </div>
                  <div class="col-md-9">
                    <el-progress
                      :text-inside="true"
                      :stroke-width="32"
                      :color="theme"
                      :percentage="getData.sat_english200_pct"
                    ></el-progress>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr class="solid" />
          <div class="section-subtitle">
            {{ showTitle("ACT SCORES OF ENROLLED FRESHMEN") }}
          </div>
          <div class="content">
            <div class="content-title">
              <span v-if="getData.avg_act">{{ getData.avg_act }} average,</span>
              <span v-if="getData.avg_act_min">
                {{ getData.avg_act_min }}-{{ getData.avg_act_max }}
                range of middle 50%
              </span>
            </div>
            <div class="content-title-value">
              <div class="chart-bar">
                <div class="row">
                  <div class="col-md-3">
                    Score of 30 - 36
                  </div>
                  <div class="col-md-9">
                    <el-progress
                      :text-inside="true"
                      :stroke-width="32"
                      :color="theme"
                      :percentage="getData.avg_act30_pct"
                    ></el-progress>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    Score of 24 - 29
                  </div>
                  <div class="col-md-9">
                    <el-progress
                      :text-inside="true"
                      :stroke-width="32"
                      :color="theme"
                      :percentage="getData.avg_act24_pct"
                    ></el-progress>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    Score of 18 - 23
                  </div>
                  <div class="col-md-9">
                    <el-progress
                      :text-inside="true"
                      :stroke-width="32"
                      :color="theme"
                      :percentage="getData.avg_act18_pct"
                    ></el-progress>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    Score of 12 - 17
                  </div>
                  <div class="col-md-9">
                    <el-progress
                      :text-inside="true"
                      :stroke-width="32"
                      :color="theme"
                      :percentage="getData.avg_act12_pct"
                    ></el-progress>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    Score of 6 - 11
                  </div>
                  <div class="col-md-9">
                    <el-progress
                      :text-inside="true"
                      :stroke-width="32"
                      :color="theme"
                      :percentage="getData.avg_act6_pct"
                    ></el-progress>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    Score of 5 or Below
                  </div>
                  <div class="col-md-9">
                    <el-progress
                      :text-inside="true"
                      :stroke-width="32"
                      :color="theme"
                      :percentage="getData.avg_act0_pct"
                    ></el-progress>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr class="solid" />
          <div class="section-subtitle">
            {{ showTitle("OTHER QUALIFICATIONS OF ENROLLED FRESHMEN") }}
          </div>
          <div class="content">
            <div class="content-title">
              {{ showTitle("High School Class Rank") }}
            </div>
            <div class="content-title-value">
              <div>
                Top tenth: {{ showReport(getData.hs_rank_10_pct, "%") }}
              </div>
              <div>
                Top quarter: {{ showReport(getData.hs_rank_25_pct, "%") }}
              </div>
              <div>Top half: {{ showReport(getData.hs_rank_50_pct, "%") }}</div>
            </div>
          </div>
          <div class="content">
            <div class="content-title">
              {{ showTitle("National Merit Scholar") }}
            </div>
            <div class="content-title-value">
              {{ showReport(getData.national_merit_pct, "%") }}
            </div>
          </div>
          <div class="content">
            <div class="content-title">{{ showTitle("Valedictorian") }}</div>
            <div class="content-title-value">
              {{ showReport(getData.valedictorian_pct, "%") }}
            </div>
          </div>
          <div class="content">
            <div class="content-title">{{ showTitle("Class President") }}</div>
            <div class="content-title-value">
              {{ showReport(getData.class_president_pct, "%") }}
            </div>
          </div>
          <div class="content">
            <div class="content-title">
              {{ showTitle("Student Government Officer") }}
            </div>
            <div class="content-title-value">
              {{ showReport(getData.student_gov_pct, "%") }}
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="showTitle('Graduate')" name="Graduate">
          <div class="section-title">
            {{ showTitle("ADMISSIONS OFFICE") }}
          </div>
          <div class="content">
            <div class="content-title">
              {{ showTitle("Address") }}
            </div>
            <div class="content-title-value">
              <div>{{ showReport(getData.ao_address) }}</div>
              <div>
                {{ showReport(getData.ao_city) }},
                {{ showReport(getData.ao_state) }}, {{ getData.ao_zipcode1 }}-{{
                  getData.ao_zipcode2
                }}
              </div>
            </div>
          </div>
          <div class="content">
            <div class="content-title">{{ showTitle("Phone") }}</div>
            <div class="content-title-value">
              {{ showReport(getData.ao_phone) }}
            </div>
          </div>
          <div class="content">
            <div class="content-title">{{ showTitle("Email") }}</div>
            <div class="content-title-value">
              {{ showReport(getData.ao_email) }}
            </div>
          </div>
          <div class="content">
            <div class="content-title">
              {{ showTitle("Undergraduate Admissions website") }}
            </div>
            <div class="content-title-value">
              <a
                :href="getData.admissions_info_url"
                v-if="getData.admissions_info_url"
                target="_blank"
                >{{ showTitle("See official website") }}</a
              >
              <div v-else>-</div>
            </div>
          </div>
          <div class="content">
            <div class="content-title">
              {{ showTitle("Undergraduate Admissions portal") }}
            </div>
            <div class="content-title-value">
              <a
                :href="getData.admissions_portal_url"
                v-if="getData.admissions_portal_url"
                target="_blank"
                >{{ showTitle("Go to your portal") }}</a
              >
              <div v-else>-</div>
            </div>
          </div>
          <hr class="solid" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import mixin from "../mixins/index.js";
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import BmMarker from "vue-baidu-map/components/overlays/Marker.vue";
export default {
  components: {
    BaiduMap,
    BmMarker
  },

  mixins: [mixin],

  props: ["getData", "lang", "theme", "name"],
  data() {
    return {
      activeName: "Undergraduate",
      center: { lng: -99.707941, lat: 32.47108 },
      zoom: 15
    };
  },
  computed: {},
  watch: {},
  created() {
    this.center.lng = this.getData.longitude;
    this.center.lat = this.getData.latitude;
  },
  mounted() {},

  methods: {
    showTitle(val) {
      return this.$t("schools.Admissions Section." + this.trimStr(val));
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../css/index.scss";
.cards-item {
  height: 250px;
}
.locationMap {
  display: flex;
  background: var(--color);
  border-radius: 8px;
  overflow: hidden;
  color: white;
}
.locationMap hr {
  border-color: white;
}
.locationMap h6 {
  color: white;
}
.bmView {
  width: 65%;
  height: 300px;
}
.locationAddress {
  width: 35%;
  padding: 0 16px 10px;
}
@media screen and (max-width: 768px) {
  .locationMap {
    flex-direction: column-reverse;
  }
  .bmView,
  .locationAddress {
    width: 100%;
  }
}
</style>
