<template>
  <div class="box">
    <div class="sections section">
      <!-- <div class="section-title">
        STUDENT ACTIVITIES
      </div>
      <div class="content">
        <div class="content-title">Activities and Organizations</div>
        <div class="content-title-value">
          choral groups, marching band, drama theatre, radio station, student
          newspaper, television station, student association, graduate students
          association, spring break campaigns, international students
          association, lynay
        </div>
      </div>
      <div class="content">
        <div class="content-title">Sororities</div>
        <div class="content-title-value">32% of women participate</div>
      </div>
      <div class="content">
        <div class="content-title">Fraternities</div>
        <div class="content-title-value">24% of men participate</div>
      </div>
      <hr /> -->
      <div class="section-title">
        {{ showTitle("STUDENT BODY") }}
      </div>
      <div class="content">
        <div class="content-title">{{ showTitle("Coeducational") }}</div>
        <div class="content-title-value">
          {{ showReport(getData.coed) }}
        </div>
      </div>
      <div class="content">
        <div class="content-title">{{ showTitle("All Undergraduates") }}</div>
        <div class="content-title-value">
          {{ showReport(toThousands(getData.undergrad_count)) }}
          <div class="content">
            <div class="content-title">Women</div>
            <div class="content-title-value">
              {{ showReport(toThousands(getData.undergrad_w_count)) }} ({{
                showReport(getData.undergrad_w_pct, "%")
              }})
            </div>
          </div>
          <div class="content">
            <div class="content-title">Men</div>
            <div class="content-title-value">
              {{ showReport(toThousands(getData.undergrad_m_count)) }} ({{
                showReport(getData.undergrad_m_pct, "%")
              }})
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="content-title">
          {{ showTitle("Full-Time Undergraduates") }}
        </div>
        <div class="content-title-value">
          {{ showReport(toThousands(getData.fulltime_undergrad_count)) }}
        </div>
      </div>
      <div class="content">
        <div class="content-title">
          {{ showTitle("Ethnicity of Students from US") }}
        </div>
        <div class="content-title-value">
          <div class="chart-bar">
            <div class="row">
              <div class="col-md-3">
                American Indian/Alaskan Native
              </div>
              <div class="col-md-9">
                <el-progress
                  :text-inside="true"
                  :stroke-width="32"
                  :color="theme"
                  :percentage="getData.indian_pct"
                ></el-progress>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                Asian
              </div>
              <div class="col-md-9">
                <el-progress
                  :text-inside="true"
                  :stroke-width="32"
                  :color="theme"
                  :percentage="getData.asian_pct"
                ></el-progress>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                Black/African-American
              </div>
              <div class="col-md-9">
                <el-progress
                  :text-inside="true"
                  :stroke-width="32"
                  :color="theme"
                  :percentage="getData.black_pct"
                ></el-progress>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                Hispanic/Latino
              </div>
              <div class="col-md-9">
                <el-progress
                  :text-inside="true"
                  :stroke-width="32"
                  :color="theme"
                  :percentage="getData.hispanic_pct"
                ></el-progress>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                Multi-race (not Hispanic/Latino)
              </div>
              <div class="col-md-9">
                <el-progress
                  :text-inside="true"
                  :stroke-width="32"
                  :color="theme"
                  :percentage="getData.mixed_pct"
                ></el-progress>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                Native Hawaiian/ Pacific Islander
              </div>
              <div class="col-md-9">
                <el-progress
                  :text-inside="true"
                  :stroke-width="32"
                  :color="theme"
                  :percentage="getData.pi_pct"
                ></el-progress>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                White
              </div>
              <div class="col-md-9">
                <el-progress
                  :text-inside="true"
                  :stroke-width="32"
                  :color="theme"
                  :percentage="getData.white_pct"
                ></el-progress>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                Unknown
              </div>
              <div class="col-md-9">
                <el-progress
                  :text-inside="true"
                  :stroke-width="32"
                  :color="theme"
                  :percentage="getData.unknown_pct"
                ></el-progress>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="content-title">
          {{ showTitle("International Students") }}
        </div>
        <div class="content-title-value">
          {{ showReport(getData.intl_pct, "%") }} from
          {{ showReport(toThousands(getData.intl_countries_count)) }} countries
        </div>
      </div>
      <div class="content">
        <div class="content-title">{{ showTitle("Average Age") }}</div>
        <div class="content-title-value">
          {{ showReport(toThousands(getData.avg_age)) }}
        </div>
      </div>
      <div class="content">
        <div class="content-title">
          {{ showTitle("All Graduate Students") }}
        </div>
        <div class="content-title-value">
          {{ showReport(toThousands(getData.grad_count)) }}
        </div>
      </div>
      <hr />
      <div class="section-title">
        {{ showTitle("UNDERGRADUATE RETENTION & GRADUATION") }}
      </div>
      <div class="content">
        <div class="content-title">
          {{ showTitle("First-Year Students Returning") }}
        </div>
        <div class="content-title-value">
          {{ showReport(getData.return_rate, "%") }}
        </div>
      </div>
      <div class="content">
        <div class="content-title">
          {{ showTitle("Students Graduating Within 4 Years") }}
        </div>
        <div class="content-title-value">
          {{ showReport(getData.graduate_4_yrs, "%") }}
        </div>
      </div>
      <div class="content">
        <div class="content-title">
          {{ showTitle("Students Graduating Within 5 Years") }}
        </div>
        <div class="content-title-value">
          {{ showReport(getData.graduate_5_yrs, "%") }}
        </div>
      </div>
      <div class="content">
        <div class="content-title">
          {{ showTitle("Students Graduating Within 6 Years") }}
        </div>
        <div class="content-title-value">
          {{ showReport(getData.graduate_6_yrs, "%") }}
        </div>
      </div>
      <hr />
      <div class="section-title">
        {{ showTitle("AFTER GRADUATION") }}
      </div>
      <div class="content">
        <div class="content-title">
          {{
            showTitle("Graduates Offered Full-Time Employment Within 6 Months")
          }}
        </div>
        <div class="content-title-value">
          {{ showReport(getData.employment_pct, "%") }}
        </div>
      </div>
      <!-- <div class="content">
        <div class="content-title">Average Starting Salary</div>
        <div class="content-title-value">
          
        </div>
      </div> -->
      <div class="content">
        <div class="content-title">
          {{ showTitle("Graduates Pursuing Advanced Study Directly") }}
        </div>
        <div class="content-title-value">
          {{ showReport(getData.pursue_grad_studies_pct, "%") }}
        </div>
      </div>
      <div class="content">
        <div class="content-title">{{ showTitle("Disciplines Pursued") }}</div>
        <div class="content-title-value">
          {{ showReport(getData.grad_disciplines_pursued) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "../mixins/index.js";
export default {
  components: {},

  mixins: [mixin],

  props: ["getData", "lang", "theme"],
  data() {
    return {};
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {
    showTitle(val) {
      return this.$t("schools.Students Section." + this.trimStr(val));
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../css/index.scss";
.cards-item {
  height: 300px;
}
</style>
