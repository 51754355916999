<template>
  <div class="box">
    <div class="profile-header">
      <div class="container">
        <div class="header-cards">
          <div class="row">
            <div class="col-md-6">
              <div class="cards-item">
                <div class="section-title">
                  <div>{{ showTitle("Tuition & Fees :") }}</div>
                  <div>
                    {{ showReport(toThousands(getData.tuition_fee), "", "$") }}
                  </div>
                </div>
                <div class="section-title">
                  <div>{{ showTitle("Room & Board :") }}</div>
                  <div>
                    {{ showReport(toThousands(getData.room_fee), "", "$") }}
                  </div>
                </div>
                <div class="section-title">
                  <div>{{ showTitle("Books & Supplies :") }}</div>
                  <div>
                    {{ showReport(toThousands(getData.books_fee), "", "$") }}
                  </div>
                </div>
                <div class="section-title">
                  <div>{{ showTitle("Other Expenses :") }}</div>
                  <div>
                    {{ showReport(toThousands(getData.other_fee), "", "$") }}
                  </div>
                </div>
                <hr />
                <div class="content-title-value">
                  {{ showReport(getData.payment_plans) }}
                </div>
                <div class="section-title">
                  <div>{{ showTitle("Cost of Attendance :") }}</div>
                  <div>
                    {{
                      showReport(toThousands(getData.total_tuition), "", "$")
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sections section">
      <!-- Financials 完成-->
      <div class="section-title">
        {{ showTitle("APPLYING FOR FINANCIAL AID") }}
      </div>
      <div class="content">
        <div class="content-title">{{ showTitle("Website") }}</div>
        <div class="content-title-value">
          <a :href="getData.finaid_website" target="_blank">
            Financial Aid Website
          </a>
        </div>
      </div>
      <div class="content">
        <div class="content-title">{{ showTitle("Net Price Calculator") }}</div>
        <div class="content-title-value">
          <a :href="getData.finaid_net_price_calc_website" target="_blank">
            Tap Here
          </a>
        </div>
      </div>
      <hr class="solid" />
      <diV class="section-subtitle">
        {{ showTitle("APPLICATION PROCESS") }}
      </diV>
      <div class="content">
        <div class="content-title">
          {{ showTitle("Application Deadline") }}
        </div>
        <div class="content-title-value">
          {{ showReport(getData.finaid_app_final_deadline) }}
        </div>
      </div>
      <div class="content">
        <div class="content-title">
          {{ showTitle("Award Notification") }}
        </div>
        <div class="content-title-value">
          {{ showReport(getData.finaid_notification_date) }}
        </div>
      </div>
      <!-- <div class="content">
        <div class="content-title">High School Program</div>
        <div class="content-title-value">
          College preparatory program is required
        </div>
      </div> -->
      <div class="content">
        <div class="content-title">
          {{ showTitle("Methodology For Awarding Institutional Aid") }}
        </div>
        <div class="content-title-value">
          {{ showReport(getData.finaid_methodology) }}
        </div>
      </div>
      <!-- <div class="content-title-value">
        <table></table>
      </div> -->
      <hr />
      <div class="section-title">
        {{ showTitle("PROFILE OF 2018-19 FINANCIAL AID") }}
      </div>
      <div class="section-subtitle">
        {{ showTitle("FRESHMAN") }}
      </div>
      <div class="content">
        <div class="content-title">
          {{ showTitle("Financial Aid Applicants") }}
        </div>
        <div class="content-title-value">
          {{ getData.finaid_freshmen_applicant_count }} ({{
            getData.finaid_freshmen_applicant_pct
          }}% of) freshmen
        </div>
      </div>
      <div class="content">
        <div class="content-title">
          {{ showTitle("Found to Have Financial Need") }}
        </div>
        <div class="content-title-value">
          {{ getData.finaid_freshmen_needed_count }} ({{
            getData.finaid_freshmen_needed_pct
          }}% of) applicants
        </div>
      </div>
      <div class="content">
        <div class="content-title">
          {{ showTitle("Received Financial Aid") }}
        </div>
        <div class="content-title-value">
          {{ getData.finaid_freshmen_received_count }} ({{
            getData.finaid_freshmen_received_pct
          }}% of) applicants with financial need
        </div>
      </div>
      <div class="content">
        <div class="content-title">{{ showTitle("Need Fully Met") }}</div>
        <div class="content-title-value">
          {{ getData.finaid_freshmen_fully_met_count }} ({{
            getData.finaid_freshmen_fully_met_pct
          }}% of) aid recipients
        </div>
      </div>
      <div class="content">
        <div class="content-title">
          {{ showTitle("Average Percent of Need Met") }}
        </div>
        <div class="content-title-value">
          {{ getData.finaid_freshmen_avg_pct_met }}%
        </div>
      </div>
      <div class="content">
        <div class="content-title">{{ showTitle("Average Award") }}</div>
        <div class="content-title-value">
          {{
            showReport(toThousands(getData.finaid_freshmen_avg_award), "", "$")
          }}
          <!-- <div class="content">
            <div class="content-title">Need-Based Gift</div>
            <div class="content-title-value">
              Received by 688 (100.0% of) aid recipients, average amount $24,266
            </div>
          </div>
          <div class="content">
            <div class="content-title">Need-Based Self-Help</div>
            <div class="content-title-value">
              Received by 422 (61.3% of) aid recipients, average amount $3,250
            </div>
          </div>
          <div class="content">
            <div class="content-title">Merit-Based Gift</div>
            <div class="content-title-value">
              Received by 677 (98.4% of) aid recipients
            </div>
          </div> -->
        </div>
      </div>
      <div class="content" v-if="getData.finaid_freshman_merit_received_count">
        <div class="content-title">{{ showTitle("Merit-Based Gift") }}</div>
        <div class="content-title-value">
          <!-- <div>Received by 677 (98.4% of) aid recipients</div> -->
          <div>
            {{ toThousands(getData.finaid_freshman_merit_received_count) }} ({{
              getData.finaid_freshman_merit_received_pct
            }}% of) freshmen had no financial need and received merit aid,
            average amount
            {{
              showReport(
                toThousands(getData.finaid_freshman_merit_received_avg),
                "",
                "$"
              )
            }}
          </div>
        </div>
      </div>
      <hr class="solid" />
      <div class="section-subtitle">
        {{ showTitle("ALL UNDERGRADUATES") }}
      </div>
      <div class="content">
        <div class="content-title">
          {{ showTitle("Financial Aid Applicants") }}
        </div>
        <div class="content-title-value">
          {{ toThousands(getData.finaid_undergrad_applicant_count) }} ({{
            getData.finaid_undergrad_applicant_pct
          }}% of) undergraduates
        </div>
      </div>
      <div class="content">
        <div class="content-title">
          {{ showTitle("Found to Have Financial Need") }}
        </div>
        <div class="content-title-value">
          {{ toThousands(getData.finaid_undergrad_needed_count) }} ({{
            getData.finaid_undergrad_needed_pct
          }}% of) applicants
        </div>
      </div>
      <div class="content">
        <div class="content-title">
          {{ showTitle("Received Financial Aid") }}
        </div>
        <div class="content-title-value">
          {{ toThousands(getData.finaid_undergrad_received_count) }} ({{
            getData.finaid_undergrad_received_pct
          }}% of) applicants with financial need
        </div>
      </div>
      <div class="content">
        <div class="content-title">{{ showTitle("Need Fully Met") }}</div>
        <div class="content-title-value">
          {{ toThousands(getData.finaid_undergrad_fully_met_count) }} ({{
            getData.finaid_undergrad_fully_met_pct
          }}% of) aid recipients
        </div>
      </div>
      <div class="content">
        <div class="content-title">
          {{ showTitle("Average Percent of Need Met") }}
        </div>
        <div class="content-title-value">
          {{ showReport(getData.finaid_undergrad_avg_pct_met, "%") }}
        </div>
      </div>
      <div class="content">
        <div class="content-title">
          {{ showTitle("Average Award") }}
        </div>
        <div class="content-title-value">
          {{
            showReport(toThousands(getData.finaid_undergrad_avg_award), "", "$")
          }}
          <!-- <div class="content">
            <div class="content-title">Need-Based Gift</div>
            <div class="content-title-value">
              Received by 2,230 (99.5% of) aid recipients, average amount
              $22,497
            </div>
          </div>
          <div class="content">
            <div class="content-title">Need-Based Self-Help</div>
            <div class="content-title-value">
              Received by 1,425 (63.6% of) aid recipients, average amount $4,120
            </div>
          </div>
          <div class="content">
            <div class="content-title">Merit-Based Gift</div>
            <div class="content-title-value">
              Received by 2,193 (97.8% of) aid recipients 1,144 (34.2% of)
              undergraduates had no financial need and received merit aid,
              average amount $14,877
            </div>
          </div> -->
        </div>
      </div>
      <hr class="solid" />
      <div class="section-subtitle">
        {{ showTitle("ALL UNDERGRADUATES") }}
      </div>
      <div class="content">
        <div class="content-title">
          {{ showTitle("2018 Graduates Who Took Out Loans") }}
        </div>
        <div class="content-title-value">
          {{ showReport(getData.student_loans_pct, "%") }}
        </div>
      </div>
      <div class="content">
        <div class="content-title">
          {{ showTitle("Average Indebtedness of 2018 Graduates") }}
        </div>
        <div class="content-title-value">
          {{ showReport(toThousands(getData.student_indebtedness), "", "$") }}
        </div>
      </div>
      <hr />
      <div class="section-title">
        {{ showTitle("FINANCIAL AID PROGRAMS") }}
      </div>
      <div class="section-subtitle">
        {{ showTitle("LOANS") }}
      </div>
      <div class="content">
        <div class="content-title">{{ showTitle("Federal Loans") }}</div>
        <div class="content-title-value">
          {{ showReport(getData.federal_loan_programs) }}
        </div>
      </div>
      <div class="content">
        <div class="content-title">{{ showTitle("State Loans") }}</div>
        <div class="content-title-value">
          <div v-if="getData.state_loan_is_available === 1">
            {{ $t("schools.Available") }}
          </div>
          <div v-if="getData.state_loan_is_available === 0">
            {{ $t("schools.Not Available") }}
          </div>
        </div>
      </div>
      <div class="content">
        <div class="content-title">{{ showTitle("Other Loans") }}</div>
        <div class="content-title-value">
          {{ showReport(getData.other_loan_programs) }}
        </div>
      </div>
      <hr class="solid" />
      <div class="section-subtitle">
        {{ showTitle("SCHOLARSHIPS AND GRANTS") }}
      </div>
      <div class="content">
        <div class="content-title">{{ showTitle("Need-Based Available") }}</div>
        <div class="content-title-value">
          {{ showReport(getData.need_based_programs) }}
        </div>
      </div>
      <div class="content">
        <div class="content-title">
          {{ showTitle("Non-Need-Based Available") }}
        </div>
        <div class="content-title-value">
          {{ showReport(getData.nonneed_based_programs) }}
        </div>
      </div>
      <hr class="solid" />
      <!-- <div class="section-subtitle">
        NON-NEED AWARDS
      </div>
      <div class="content">
        <div class="content-title">
          Academic Interest/Achievement Award Areas
        </div>
        <div class="content-title-value">
          <div>Agriculture</div>
          <div>Biological Sciences</div>
          <div>Business</div>
          <div>Communication</div>
          <div>Education</div>
          <div>Engineering/Technologies</div>
          <div>English</div>
          <div>Foreign Languages</div>
          <div>Mathematics</div>
          <div>Physical Sciences</div>
          <div>Religion/Biblical Studies</div>
          <div>
            Social Sciences
            <div class="content">
              <div class="content-title">Number of Awards</div>
              <div class="content-title-value">Not reported</div>
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="content-title">Creative Arts/Performance Award Areas</div>
        <div class="content-title-value">
          <div>Applied Art and Design</div>
          <div>Debating</div>
          <div>Journalism/Publications</div>
          <div>Music</div>
          <div>
            Theater/Drama
            <div class="content">
              <div class="content-title">Number of Awards</div>
              <div class="content-title-value">Not reported</div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
      <div class="content">
        <div class="content-title">
          Special Achievements/Activities Award Areas
        </div>
        <div class="content-title-value">
          <div>Cheerleading/Drum Major</div>
          <div>
            Leadership
            <div class="content">
              <div class="content-title">Number of Awards</div>
              <div class="content-title-value">Not reported</div>
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="content-title">Special Characteristics Award Areas</div>
        <div class="content-title-value">
          <div>Children of Faculty/Staff</div>
          <div>Ethnic Background</div>
          <div>First-Generation College Students</div>
          <div>Local/State Students</div>
          <div>Members of Minorities</div>
          <div>Out-of-State Students</div>
          <div>Previous College Experience</div>
          <div>Relatives of Clergy</div>
          <div>
            Religious Affiliation
            <div class="content">
              <div class="content-title">Number of Awards</div>
              <div class="content-title-value">Not reported</div>
            </div>
          </div>
        </div>
      </div>
      <hr class="solid" /> -->
      <div class="section-subtitle">
        {{ showTitle("EMPLOYMENT") }}
      </div>
      <div class="content">
        <div class="content-title">{{ showTitle("Work-Study Programs") }}</div>
        <div class="content-title-value">
          {{ showReport(getData.workstudy_programs) }}
        </div>
      </div>
      <div class="content">
        <div class="content-title">
          {{ showTitle("Average Earnings from On-Campus Employment") }}
        </div>
        <div class="content-title-value">
          {{ showReport(getData.workstudy_avg_earning) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "../mixins/index.js";

export default {
  components: {},

  mixins: [mixin],

  props: ["getData", "lang", "theme"],
  data() {
    return {};
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {
    showTitle(val) {
      return this.$t("schools.Financials Section." + this.trimStr(val));
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../css/index.scss";
</style>
